<template>
  <div class="flex flex-col p-4">
    <div class="font-semibold text-center">Ticket Form</div>
    <div class="text-center text-hgray8 text-sm">
      Complete the ticket data in each column provided
    </div>

    <div class="form-control mt-4">
      <label class="label">
        <span class="label-text">Division</span>
        <div class="inline-flex whitespace-nowrap" v-if="v$.form.division_id.$error">
          <img :src="SvgWarning" class="mr-2" />
          <span
            class="text-red-500 italic"
            style="font-size: 14px; line-height: 1; font-weight: 300"
          >
            Please select the division first!
          </span>
        </div>
      </label>
      <treeselect
        ref="service-ticket-division"
        class="h-9"
        :multiple="false"
        v-model="form.division_id"
        :options="listDivision"
        :clearable="false"
        @select="setDivision"
      />
    </div>
    <div class="form-control mt-4">
      <label class="label">
        <span class="label-text">Instrument</span>
        <div class="inline-flex whitespace-nowrap" v-if="v$.form.instrument_id.$error">
          <img :src="SvgWarning" class="mr-2" />
          <span
            class="text-red-500 italic"
            style="font-size: 14px; line-height: 1; font-weight: 300"
          >
            Please select the instrument first!
          </span>
        </div>
      </label>
      <treeselect
        ref="service-ticket-instrument"
        class="h-9"
        :multiple="false"
        v-model="form.instrument_id"
        :options="listInstrument"
        :disabled="!form.division_id"
      >
        <label slot="option-label" slot-scope="{ node, labelClassName }" :class="labelClassName">
          <div>{{ node.label }}</div>
          <div class="font-bold" style="font-size: 10px; color: #3c4d69d1">
            {{ node.raw.type }}
          </div>
        </label>
      </treeselect>
    </div>

    <div class="form-control mt-4">
      <label class="label">
        <span class="label-text">Note</span>
        <div class="inline-flex whitespace-nowrap" v-if="v$.form.description.$error">
          <img :src="SvgWarning" class="mr-2" />
          <span
            class="text-red-500 italic"
            style="font-size: 14px; line-height: 1; font-weight: 300"
          >
            Note is required!
          </span>
        </div>
      </label>
      <textarea
        class="textarea h-24 textarea-bordered placeholder"
        placeholder="Type note here..."
        v-model="form.description"
      ></textarea>
    </div>

    <div class="form-control mt-4">
      <label class="label">
        <span class="label-text">Contact person</span>
        <div class="inline-flex whitespace-nowrap" v-if="v$.form.staff_name.$error">
          <img :src="SvgWarning" class="mr-2" />
          <span
            class="text-red-500 italic"
            style="font-size: 14px; line-height: 1; font-weight: 300"
          >
            Please enter contact person first!
          </span>
        </div>
      </label>
      <input
        type="text"
        v-model="form.staff_name"
        placeholder="Type contact person here..."
        class="input input-bordered transition rounded-lg w-full py-2 px-3 h-9 focus:ring-transparent"
      />
    </div>
    <div class="form-control mt-4">
      <label class="label">
        <span class="label-text">Phone number</span>
        <div class="inline-flex whitespace-nowrap" v-if="v$.form.staff_phone_number.$error">
          <img :src="SvgWarning" class="mr-2" />
          <span
            class="text-red-500 italic"
            style="font-size: 14px; line-height: 1; font-weight: 300"
          >
            Please enter phone number first!
          </span>
        </div>
      </label>
      <input
        type="number"
        v-model="form.staff_phone_number"
        placeholder="Type phone number here..."
        class="input input-bordered transition rounded-lg w-full py-2 px-3 h-9 focus:ring-transparent"
      />
    </div>

    <div
      style="width: 100%; height: 14px; border-bottom: 2px solid #363636"
      class="text-center mt-4"
    >
      <span class="bg-white text-sm font-bold" style="padding: 0 7px"> Priority </span>
    </div>

    <div class="flex space-x-2 justify-between overflow-x-auto mt-4">
      <div
        class="pt-2.5 relative cursor-pointer flex-auto"
        v-for="p in priorities"
        :key="p"
        :class="p == form.priority ? `act-btn-prio bg-h${form.priority}` : 'list-btn-prio'"
        @click="setPrio(p)"
      >
        <p
          class="font-bold text-sm text-center capitalize"
          :class="p == form.priority ? `text-htext${form.priority}` : 'text-black'"
        >
          {{ p }}
        </p>
      </div>
    </div>

    <div class="mt-8 mb-4 text-center w-full">
      <button
        class="btn btn-primary text-base h-12 px-10 mx-auto capitalize border-0 hover:bg-hblue"
        @click="submit"
      >
        Confirm
      </button>
    </div>
  </div>
</template>

<script>
import group357 from '@/assets/images/group357.png'
// import ErrorTicket from '@/assets/images/error-ticket.png'
import SvgWarning from '@/assets/icons/icon-warning.svg'

import { mapActions, mapGetters } from 'vuex'
import Treeselect from '@riophae/vue-treeselect'

// import VModal from '@/components/modal/MConfirmTechnical'

// Vue.component('v-modal', require('@/components/modal/MConfirmTechnical.vue'))
import useVuelidate from '@vuelidate/core'
import { required, requiredIf } from '@vuelidate/validators'

export default {
  name: 'open-technical',
  components: {
    Treeselect
    // VModal
  },
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      group357,
      // ErrorTicket,
      SvgWarning,

      form: {
        instrument_id: null,
        description: '',
        staff_name: '',
        staff_phone_number: '',
        division_id: null,
        priority: 'low'
      },
      priorities: ['low', 'medium', 'high'],
      divLoading: false
    }
  },
  validations() {
    return {
      form: {
        instrument_id: {
          required: requiredIf(function () {
            return this.form.division_id == 1 ? false : true
          })
        },
        description: { required },
        staff_name: { required },
        staff_phone_number: { required },
        division_id: { required },
        priority: { required }
      }
    }
  },
  computed: {
    ...mapGetters('ticket', ['listInstrument', 'listDivision'])
  },
  methods: {
    ...mapActions('modal', ['MODAL_CONFIRM_NEW_TICKET', 'MODAL_ERROR']),
    ...mapActions('ticket', ['PRE_OPEN_TICKET', 'TICKET_FORM', 'GET_INSTRUMENTS']),

    async setDivision(node, instanceId) {
      let id = node ? node.id : null

      let res = instanceId ? false : false
      this.form.instrument_id = null

      if (!this.divLoading) {
        this.divLoading = true
        if (id) res = await this.PRE_OPEN_TICKET({ division_id: id })

        this.divLoading = false
      }

      if (res) {
        this.form.division_id = id
        this.GET_INSTRUMENTS({
          division_id: id
        })
      } else {
        this.form.division_id = null
        this.MODAL_ERROR(true)
      }
    },
    setPrio(val) {
      this.form.priority = val
    },
    async submit() {
      const result = await this.v$.$validate()
      if (!result) {
        return
      }

      let form = this.form
      form.ticket_type_id = this.$route.params.id

      await this.TICKET_FORM(form)

      this.MODAL_CONFIRM_NEW_TICKET(true)
    }
  }
}
</script>
